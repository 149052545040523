import "./App.css";
import React from "react";
import { Nav, Text } from "components";
import { Router } from "router";

function App() {
  return (
    <main className="min-h-dvh">
      <Router />
    </main>
  );
}

export default App;
