import React from "react";

export default function Body({ className, children }) {
  return (
    <p
      className={` ${className} text-xl text-dark animate-fade-in-up font-montserrat font-medium`}
    >
      {children}
    </p>
  );
}
