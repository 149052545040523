import React, { createContext, useState, useEffect } from "react";

const WindowContext = createContext();

const WindowProvider = ({ children }) => {
  const [isXL, setIsXL] = useState(false);
  const [top, setTop] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);
  useEffect(() => {
    const handleResize = () => {
      setX(window.innerWidth);
      setY(window.innerHeight);
      setIsXL(window.innerWidth > 1280);
      setMobile(window.innerWidth < 720);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WindowContext.Provider value={{ isXL, top, mobile, x, y }}>
      {children}
    </WindowContext.Provider>
  );
};

export { WindowContext, WindowProvider };
