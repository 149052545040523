import React from "react";

const Heading = ({ className, level = 1, style, children }) => {
  const Tag = `h${level}`;

  return (
    <div
      style={style}
      className={`${className} text-3xl/6 font-montserrat font-semibold break-words animate-fade-in-left hyphens-auto text-dark/80`}
    >
      <Tag>{children}</Tag>
    </div>
  );
};

export default Heading;
