import { Text } from "components";
import React from "react";
import { TailSpin } from "react-loading-icons";

export default function LoadingSpinner() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-dvh gap-5 bg-dark">
      <TailSpin
        stroke={"#F6FEF4"}
        fill={"#F6FEF4"}
        strokeWidth={5}
        className="overflow-visible animate-fade-in-left"
      />
      <Text.Header
        className={"!text-xl !animate-fade-in-up !text-center !text-bright"}
      >
        Please wait…
      </Text.Header>
    </div>
  );
}
