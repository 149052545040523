import React from "react";
import { TailSpin } from "react-loading-icons";

const Button = ({ onMouseUp, className, children, doneLoading = true }) => {
  return (
    <>
      {doneLoading ? (
        <div
          onMouseUp={onMouseUp}
          className={`${className} flex justify-center gap-1 items-center py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors hover:bg-dark/80 font-medium text-bright rounded-md cursor-pointer pointer-events-auto font-montserrat w-fit bg-dark`}
        >
          {children}
        </div>
      ) : (
        <div
          className={`${className} flex justify-center gap-1 items-center py-3 my-1 px-4 text-sm/none select-none tracking-tight transition-colors bg-dark/80 font-medium text-bright rounded-md font-montserrat w-fit`}
        >
          <TailSpin
            className="h-[.875rem] w-fit mr-1 overflow-visible"
            strokeWidth={5}
          />{" "}
          Please Wait
        </div>
      )}
    </>
  );
};
export default Button;
