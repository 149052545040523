import React from "react";

import { createContext, useContext } from "react";
import {
  query,
  collection,
  where,
  getDocs,
  doc,
  limit,
  updateDoc,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { FirebaseContext, MessageContext } from "context";

const FirestoreContext = createContext();

const FirestoreProvider = ({ children }) => {
  const { useFirestore } = useContext(FirebaseContext);
  const { newMessage } = useContext(MessageContext);
  const firestore = useFirestore();

  function getData(doc) {
    try {
      const data = doc.data();
      if (!data)
        return () => {
          window.location.href = "https://forevermidwest.com";
          return null;
        };
      return {
        id: doc.id,
        ...data,
      };
    } catch (error) {
      console.error(error);
      newMessage({
        type: "error",
        message: "Error getting data",
      });
    }
  }

  function getSnapshot(collectionName, id) {
    try {
      const collectionRef = collection(firestore, collectionName);
      const docRef = doc(collectionRef, id);
      return getDoc(docRef).then((doc) => doc);
    } catch (error) {
      console.error(error);
      newMessage({
        type: "error",
        message: "Error getting snapshot",
      });
    }
  }

  const values = {
    getData,
    getSnapshot,
  };
  return (
    <FirestoreContext.Provider value={values}>
      {children}
    </FirestoreContext.Provider>
  );
};

export { FirestoreProvider, FirestoreContext };
