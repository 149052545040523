import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FirestoreContext } from "context";
import {
  Badge,
  Breadcrumbs,
  Button,
  Image,
  LoadingSpinner,
  Text,
} from "components";
import {
  FaApple,
  FaArrowLeft,
  FaBandcamp,
  FaCheck,
  FaGlobe,
  FaSoundcloud,
  FaSpotify,
  FaYoutube,
} from "react-icons/fa6";
import { redirect } from "react-router-dom";
export default function TrackPage() {
  // get the :id

  const { id } = useParams();
  const { getSnapshot, getData } = useContext(FirestoreContext);
  const [data, setData] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);
  useEffect(() => {
    let mounted = true;
    let timeout = 50;
    const getLinkData = async () => {
      const docSnapshot = await getSnapshot("links", id);
      const dataSnapshot = getData(docSnapshot);
      if (!dataSnapshot) return console.log("no data", dataSnapshot);
      setData(dataSnapshot);

      setDoneLoading(true);
    };
    setTimeout(() => {
      if (mounted) {
        getLinkData();
      }
    }, timeout);
    return () => (mounted = false);
  }, []);

  if (!doneLoading) return <LoadingSpinner />;
  return (
    <div
      className={`min-h-dvh grid justify-center items-center relative h-full w-full  bg-dark`}
    >
      <div className="absolute w-full h-full top-0 max-h-full left-0 z-0 pointer-events-none overflow-hidden  flex max-w-full">
        <Image
          src={data.image}
          className={"!object-cover w-full h-full blur-lg"}
        />
      </div>
      <div className="min-h-full w-full flex justify-center z-10 items-center p-10">
        <div className="bg-bright rounded-md gap-2 flex flex-col justify-start items-center p-2 h-full sm:w-[400px] max-w-full drop-shadow-lg">
          <Image src={data.image} className={"sm:h-96 w-full mb-4"} />
          <Text.Header
            className={
              "w-fit text-center !text-black tracking-tight !font-bold"
            }
          >
            {data.title}
          </Text.Header>
          <Text.SubHeader className={"text-pretty w-full text-center"}>
            {data.artist}
          </Text.SubHeader>
          <div className="flex flex-col w-full h-fit">
            {data.links?.map((link, index) => (
              <Card link={link} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  function Card({ link }) {
    const icons = {
      spotify: <FaSpotify className=" h-6 w-6 " />,
      youtube: <FaYoutube className=" h-6 w-6 " />,
      soundcloud: <FaSoundcloud className=" h-6 w-6 " />,
      "apple music": <FaApple className=" h-6 w-6 " />,
      bandcamp: <FaBandcamp className=" h-6 w-6 " />,
      other: <FaGlobe className=" h-6 w-6 " />,
    };

    return (
      <div className="w-full p-2 flex justify-between items-center gap-2">
        <Button.Outline
          href={link.link}
          className={
            "w-full gap-2 !justify-start text-black !transition-shadow !bg-bright drop-shadow-none"
          }
        >
          <div className="text-black">
            {icons[link.name.toLowerCase()] || icons.other}
          </div>
          <Text.SubHeader
            className={"capitalize line-clamp-1 tracking-tighter font-normal"}
          >
            {link.name}
          </Text.SubHeader>
        </Button.Outline>
      </div>
    );
  }

  function DateDisp({ date }) {
    date = date?.toDate();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date ? new Date(date).toLocaleDateString(undefined, options) : "";
  }
}
