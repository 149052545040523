import React, { useState } from "react";

export default function Ghost({
  children,
  href = "",
  initialState = "unselected",
  Icon,
  HoverIcon,
}) {
  const buttonState = initialState;
  const [hovered, setHovered] = useState(false);
  return (
    <a
      href={href}
      class={`capitalize text-sm gap-1 font-medium bg-transparent text-primary hover:text-bright hover:bg-primary flex justify-center items-center text-center transition-colors h-full w-fit px-10 min-w-16" ${
        buttonState === "selected" && "underline underline-offset-2 "
      }`}
      aria-current="page"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {!hovered && Icon}
      {hovered && HoverIcon}
      {children}
    </a>
  );
}
