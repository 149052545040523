import { WindowContext, WindowProvider } from "./Window";
import React from "react";
import { FirebaseProvider, FirebaseContext } from "./firebase/App";
import { FirestoreProvider, FirestoreContext } from "./firebase/Firestore";
import { MessageProvider, MessageContext } from "./Message";

const ContextProviders = ({ children }) => {
  return (
    <MessageProvider>
      <FirebaseProvider>
        <FirestoreProvider>
          <WindowProvider>
            <>{children}</>
          </WindowProvider>
        </FirestoreProvider>
      </FirebaseProvider>
    </MessageProvider>
  );
};

export {
  ContextProviders,
  FirebaseContext,
  FirestoreContext,
  WindowContext,
  MessageContext,
};
