import React from "react";
import { Home, TrackPage } from "pages";
import {
  createRoutesFromElements,
  RouterProvider,
  Route,
  createBrowserRouter,
} from "react-router-dom";
export default function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          Component={() => {
            window.location.href = "https://forevermidwest.com";
            return null;
          }}
        />

        <Route path="/:id" element={<TrackPage />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
}
